<template>
  <a-spin :spinning="loading">
    <div>
      <div class="header-top">
        <div class="left-wrap">
          <div class="header-title">当前学员</div>
          <div v-if="pageType === 'studentList'" style="margin-top: 5px; font-size: 15px">
            已选中<span style="color: #43d186">{{ this.totalStudent }}名</span>学员<span style="color: #908f8f"
              >(列表学员)</span
            >，请选择海报批量下载
          </div>
          <div class="student-info" v-else>
            <img
              :src="studentProfile.headImageUrl || 'https://cdnwukong.com/public/im/user.png'"
              class="student-avtor"
            />
            <span style="margin-left: 10px">{{ studentProfile.fullName }}</span>
            <span style="color: rgb(102, 102, 102)">（{{ studentProfile.code }}）</span>
          </div>
        </div>
        <div class="right-wrap" v-if="pageType !== 'studentList'">
          <share-link :studentProfile="studentProfile" :originType="activeName"></share-link>
        </div>
      </div>
      <div class="poster-container">
        <el-tabs
          v-model="activeName"
          tab-position="left"
          type="border-card"
          class="poster-content"
          :style="{ height: posterListHeight + 40 + 'px' }"
          @tab-click="handleClick"
        >
          <el-tab-pane label="分享海报" name="share" v-if="isTeachingStaff">
            <a-radio-group v-model="subjectName" style="margin-top: 20px" @change="changeSubject">
              <a-radio value="CHINESE"> 中文 </a-radio>
              <a-radio value="MATH"> 数学 </a-radio>
              <a-radio value="ENGLISH"> 英文 </a-radio>
            </a-radio-group>
            <!-- <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div> -->

            <PosterList
              :posterList="posterList"
              :subject="subjectName"
              :posterType="activeName"
              @heightChange="heightChange"
            />
          </el-tab-pane>
          <el-tab-pane label="一家多娃" name="child">
            <a-radio-group v-model="subjectName" style="margin-top: 20px" @change="changeSubject">
              <a-radio value="CHINESE"> 中文 </a-radio>
              <a-radio value="MATH"> 数学 </a-radio>
              <a-radio value="ENGLISH"> 英文 </a-radio>
            </a-radio-group>
            <!-- <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div> -->

            <PosterList
              :posterList="posterList"
              :subject="subjectName"
              :posterType="activeName"
              @heightChange="heightChange"
            />
          </el-tab-pane>
          <el-tab-pane label="学员拓科" name="expand">
            <a-radio-group v-model="subjectName" style="margin-top: 20px" @change="changeSubject">
              <a-radio value="CHINESE"> 中文 </a-radio>
              <a-radio value="MATH"> 数学 </a-radio>
              <a-radio value="ENGLISH"> 英文 </a-radio>
            </a-radio-group>
            <!-- <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div> -->

            <PosterList
              :posterList="posterList"
              :subject="subjectName"
              :posterType="activeName"
              @heightChange="heightChange"
            />
          </el-tab-pane>
          <el-tab-pane label="课程推荐" name="recommend" v-if="pageType !== 'studentList'">
            <a-radio-group v-model="subjectName" style="margin-top: 20px" @change="changeSubject">
              <a-radio value="CHINESE"> 中文 </a-radio>
              <a-radio value="MATH"> 数学 </a-radio>
            </a-radio-group>
            <!-- <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div> -->

            <PosterList
              :posterList="posterList"
              :subject="subjectName"
              :posterType="activeName"
              @heightChange="heightChange"
            />
          </el-tab-pane>
          <!-- <el-tab-pane label="中文海报" name="CHINESE">
            <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div>

            <PosterList :posterList="posterList" :posterType="activeName" />
          </el-tab-pane>
          <el-tab-pane label="数学海报" name="MATH">
            <div class="posterTip">
              提示：请移动鼠标至海报上方，点击【下载】按钮，系统将自动完成专属二维码的生成，并下载海报至本地
            </div>

            <PosterList :posterList="posterList" :posterType="activeName" />
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </div>
  </a-spin>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import PosterList from './posterList.vue';
import { getPosterList, queryExpandSubjectPoster } from '@/api/poster';
import { getStudentInfo } from '@/api/headTeacher';
import ShareLink from '@/views/im/ShareLink.vue';
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';
const session = storage.createStore(sessionStorage);

export default {
  components: {
    PosterList,
    ShareLink,
  },
  computed: {
    isTeachingStaff() {
      return this.userInfo.roleArr.includes('TEACHING_STAFF') || this.userInfo.roleArr.includes('TEACHING_STAFF_ADMIN');
    },
  },
  data() {
    return {
      studentParams: {},
      posterListHeight: 0,
      totalStudent: 0,
      pageType: '',
      loading: false,
      posterList: [],
      /**
       * active tab name
       */
      activeName: 'child',
      subjectName: '',
      studentList: [],
      studentProfile: session.get('studentProfile'),
      type: '',
      userID: storage.get('userInfo').userID,
      userInfo: storage.get('userInfo'),
    };
  },
  created() {
    // 从学生列表页面过来
    this.pageType = this.$route.query.pageType;
    if (this.pageType) {
      this.totalStudent = this.$route.query.batchStudent;
      this.studentParams = JSON.parse(this.$route.query.batchParams);
      this.subjectName = this.userInfo.subject; // 是数学班主任还是中文班主任
    } else {
      // 学生详情页面点进来
      this.subjectName = this.studentProfile.percentSubject;
    }
    
    
    // 多娃的海报
    this.type = this.$route.query.type || (this.isTeachingStaff ? 'MINI_PROGRAM_POSTER' : 'TRACK_POSTER');
    // 一家多娃
    if (this.type === 'TRACK_POSTER') {
      this.activeName = 'child';
    } else if (this.type === 'MINI_PROGRAM_POSTER') {
      // 学生分享
      this.activeName = 'share';
    }
    

    this.getPosterListData('');
  },
  methods: {
    heightChange(e) {
      this.posterListHeight = e;
    },
    // 拓科海报查询
    queryExpandSubjectPoster() {
      this.loading = true;
      let quSubject = '';
      if (this.subjectName === 'CHINESE') {
        quSubject = 'Chinese';
      } else if (this.subjectName === 'MATH') {
        quSubject = 'Math';
      } else if (this.subjectName === 'ENGLISH') {
        quSubject = 'English';
      }
      const params = {
        subject: quSubject,
        classAdmin: this.userID,
      };
      queryExpandSubjectPoster(params)
        .then((res) => {
          const { data } = res;

          this.posterList = data?.content || [];
        })
        .finally(() => (this.loading = false));
    },
    // // 搜索服务学生列表
    // getStudentInfo() {
    //   let params={

    //   }
    //   getStudentInfoPage().then((res) => {
    //     const data = res.data.content;
    //     const studentList = data.list;
    //     this.$store.commit('updatePosterStudentList', studentList);
    //   });
    // },
    getPosterListData(param, type = '') {
      this.loading = true;
      const params = {
        type: this.type,
      };
      getPosterList(param, params)
        .then((res) => {
          const { data } = res;
          if (type === 'math') {
            this.posterList = data?.content?.appletPosterMath || [];
            return;
          }
          if (type === 'english') {
            this.posterList = data?.content?.appletPosterEnglish || [];
            return;
          }
          this.posterList = data?.content || [];
        })
        .finally(() => (this.loading = false));
    },
    changeSubject(e) {
      const name = e.target.value;
      if (
        name === 'CHINESE' &&
        (this.activeName === 'share' || this.activeName === 'child' || this.activeName === 'recommend')
      ) {
        this.getPosterListData('');
      } else if (
        name === 'MATH' &&
        (this.activeName === 'share' || this.activeName === 'child' || this.activeName === 'recommend')
      ) {
        this.getPosterListData('math', 'math');
      } else if (
        name === 'ENGLISH' &&
        (this.activeName === 'share' || this.activeName === 'child' || this.activeName === 'recommend')
      ) {
        this.getPosterListData('english', 'english');
      } else if (this.activeName === 'expand') {
        this.queryExpandSubjectPoster();
      }
    },
    handleClick(tab) {
      const { label, name } = tab;

      reportEvent({
        data: {
          Code: this.studentProfile.code,
          tab_name: label,
          _event: 'CAstudio_ShareTabClick',
          User_id: getUserUUID(),
        },
      });

      // 从学生列表页面进来的
      if (this.pageType) {
        this.subjectName = this.userInfo.subject; // 是数学班主任还是中文班主任
      } else {
        // 学生详情页面点进来
        this.subjectName = this.studentProfile.percentSubject;
      }
      this.activeName = name;
      if (this.activeName === 'child') {
        this.type = 'TRACK_POSTER';
      } else if (this.activeName === 'share') {
        this.type = 'MINI_PROGRAM_POSTER';
      } else if (this.activeName === 'recommend') {
        this.type = 'COURSE_RECOMMEND';
      }
      if (
        this.subjectName === 'CHINESE' &&
        (this.activeName === 'share' || this.activeName === 'child' || this.activeName === 'recommend')
      ) {
        this.getPosterListData('');
      } else if (
        this.subjectName === 'MATH' &&
        (this.activeName === 'share' || this.activeName === 'child' || this.activeName === 'recommend')
      ) {
        this.getPosterListData('math', 'math');
      } else if (this.activeName === 'expand') {
        this.subjectName = this.subjectName === 'CHINESE' ? 'MATH' : 'CHINESE';

        this.queryExpandSubjectPoster();
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.poster-container {
  padding: 20px 20px 20px 0;
  background: #ffffff;
}
.poster-content {
  min-height: 300px;
}
.el-tabs--border-card {
  border: none;
  box-shadow: none;
  background: #f7f7f7;
  border-radius: 0px 30px 30px 30px;
}
.el-tabs__nav-scroll {
  background: #ffffff;
  border: none;
}
.el-tabs__header.is-left {
  border-bottom: 1px solid #fff;
  // min-height: calc(100vh - 130px);
}
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left.is-active {
  border: 1px solid #f7f7f7;
  border-left: 6px solid #43d186;
  background: #f7f7f7;
}
.el-tabs--left.el-tabs--border-card .el-tabs__item.is-left {
  padding: 0px 50px;
  font-size: 16px;
  height: 70px;
  line-height: 70px;
}
.el-tabs--left .el-tabs__header.is-left {
  margin-right: 0px;
}
.el-tabs--border-card {
  .el-tabs__content {
    padding-top: 0px;
  }
}
.posterTip {
  padding: 10px 0;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #43d186;
}
.el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #43d186;
}
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
}
.right-wrap {
  display: flex;
}
.share-btnWrap {
  height: 36px;
  background: #f7f7f7;
  border-radius: 10px;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
  margin-left: 10px;
}

.icon_enter {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
</style>
