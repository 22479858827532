<template>
  <div class="poster-card" ref="posterCard" :style="{ padding: padding }">
    <PosterCard
      :src="item.contentUrl"
      :name="item.name"
      v-for="item in posterImgs"
      :key="item.uuid"
      :posterType="type"
      :link="item.link"
      :activityId="item.activityId"
      :posterId="item.uuid"
      :subject="subject"
    />
  </div>
</template>

<script>
import PosterCard from './posterCard.vue';

export default {
  props: ['posterList', 'posterType', 'subject'],
  components: {
    PosterCard,
  },
  data() {
    return {
      posterImgs: [],
      hover: true,
      type: this.posterType,
      posterCardWidth: 0,
      posterCardHeight: 0,
      padding: '0 10px',
    };
  },
  mounted() {
    this.getPosterCardClass();
    window.onresize = () => {
      return (() => {
        if (this.$refs?.posterCard?.clientWidth) {
          this.posterCardWidth = this.$refs.posterCard.clientWidth;
        }
      })();
    };

    // 获取高度
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        // 获取元素的新尺寸
        const { height } = entry.contentRect;
        this.posterCardHeight = height;
        this.$emit('heightChange', height);
      }
    });
    resizeObserver.observe(this.$refs?.posterCard);
  },
  watch: {
    posterList(newVal) {
      this.posterImgs = newVal;
      this.getPosterCardClass();
    },
    posterType(newVal) {
      this.type = newVal;
      this.getPosterCardClass();
    },
    posterCardWidth() {
      this.getPosterCardClass();
    },
  },
  created() {
    if (this.posterList.length === 0) {
      this.posterImgs = [];
    }
  },
  methods: {
    getPosterCardClass() {
      this.justifyContentCenter = 'left';
      this.posterCardWidth = this.$refs.posterCard.clientWidth;
      const posterContainerWidth = 278;
      if (this.posterCardWidth < 0) {
        return;
      }
      const lineNumber = Math.floor(this.posterCardWidth / posterContainerWidth);
      if (this.posterImgs.length > lineNumber) {
        const padding = this.posterCardWidth - lineNumber * posterContainerWidth;
        const paddingLr = padding - 15;
        if (paddingLr > 0) {
          this.padding = `0 ${paddingLr / 2}px`;
        }
      }
    },
  },
};
</script>

<style lang="less">
.poster-card {
  display: flex;
  flex-wrap: wrap;
  // height: calc(100vh - 130px);
  overflow-x: auto;
  cursor: pointer;
  padding-bottom: 20px;
}
</style>
