<template>
  <div class="poster-item" @mouseenter="hover = true" @mouseleave="hover = false">
    <img :src="posterSrc" />
    <canvas id="mycanvas" width="750" height="1334" class="canvas"></canvas>
    <div v-if="hover" class="item-modal">
      <div>
        <div class="download-title" v-if="pageType !== 'studentList'">
          点击生成<span class="student-name">{{ studentProfile.fullName }}({{ studentProfile.code }})</span>的专属海报
        </div>

        <!-- <div class="download-btn" @click="handlePoster">
          <img src="@/assets/backlog_icon_download.png" class="icon_enter" />
          <span class="look-btn">下载</span>
        </div> -->
        <div class="download-btn" v-if="pageType === 'studentList'" @click="handlePosterAll">
          <img src="@/assets/backlog_icon_download.png" class="icon_enter" />
          <span class="look-btn">批量下载</span>
        </div>
        <div class="download-btn" v-else @click="copyPoster">
          <span class="look-btn">生成专属海报</span>
        </div>
      </div>
    </div>
    <a-modal
      v-model="visible"
      :maskClosable="false"
      title="批量下载"
      ok-text="开始下载"
      cancel-text="取消"
      @ok="batchDownload"
    >
      <div style="margin-bottom: 10px">请先选择要下载海报的学生</div>
      <a-input-search
        placeholder="搜索学生姓名或学号"
        v-model="studentName"
        enter-button="搜索"
        size="large"
        @search="onSearch"
      />
      <a-table
        :rowKey="(record) => record.uuid"
        :maskClosable="false"
        :pagination="pagination"
        @change="handleTableChange"
        size="small"
        :scroll="{ y: 400 }"
        :row-selection="{
          onSelect: onSelect,
          onSelectAll: onSelectAll,
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :data-source="studentPosterList"
      />
      <div style="margin-top: 10px">
        已选<span style="color: #009cff">{{ selectedRowKeys.length }}</span
        >人
      </div>
    </a-modal>
    <a-modal :maskClosable="false" :closable="false" v-model="visiblePercent" :footer="null">
      <div class="percent-txt">批量生成并下载学生专属海报中，请稍后···</div>
      <a-progress :percent="percent" status="active" />
    </a-modal>
    <a-modal
      title="海报详情"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      :visible="visibleReport"
      @ok="handleSubmit"
      okText="复制"
      cancelText="关闭"
      width="423px"
      dialogClass="schedule-related"
      @cancel="onCancelReport"
    >
      <div class="modal-height" style="height: 667px">
        <a-spin size="large" :spinning="spinning">
          <!-- 生成海报模板 -->
          <div class="studyReportTemplate" ref="studyReportTemplateTC" style="height: 667px">
            <span class="codeIdent" v-if="posterType === 'share'">{{ codeIdent }}</span>
            <img :class="type === 'share' ? 'qrCodeShare' : 'qrCode'" :src="qrcodeImg" />
            <img ref="posterImage" :src="src" style="width: 375px; height: 667px" />
          </div>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import {
  getPosterQrcode,
  getExpandPosterQrcode,
  batchDownloadExpand,
  batchDownloadExpandNew,
  queryWaterMarkCode,
} from '@/api/poster';
import downLoadPosterImg from './downLoadPosterImg.js';
import { CURRENT_CONVERSATION_STUDENT } from '@/store/mutation-types';
import { getStudentInfoPage, batchDownload, queryStudentClassHourNew } from '@/api/headTeacher';
import downloadBlob from '@/utils/download-blob';
import html2canvas from 'html2canvas';
import { getUserUUID } from '@/utils/user';
import { reportEvent } from '@/utils/gatherer';

const columns = [
  {
    title: '学生姓名',
    dataIndex: 'fullName',
  },
  {
    title: '学号',
    dataIndex: 'code',
    width: 120,
  },
];

const session = storage.createStore(sessionStorage);

export default {
  props: ['src', 'name', 'posterType', 'posterId', 'link', 'subject', 'activityId'],
  data() {
    return {
      codeIdent: '',
      pageType: '',
      studentParams: {},
      iconLoading: false,
      spinning: false,
      posterUrl: '',
      visibleReport: false,
      pagination: {
        total: 0,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'], // 每页中显示的数据
        // showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      studentProfile: session.get('studentProfile'),
      currentPage: 1,
      pageSize: 10,
      studentPosterList: [],
      columns,
      visiblePercent: false,
      percent: 0,
      studentName: '',
      qrcodeImg: '',
      posterSrc: this.src,
      type: this.posterType,
      posterName: this.name,
      hover: false,
      visible: false,
      studentData: [],
      selectedRowKeys: [],
      selStudents: [],
      selectedRows: [],
      roleName: storage.get('userInfo').roleName,
      userID: storage.get('userInfo').userID,
    };
  },
  watch: {
    src(newVal) {
      this.posterSrc = newVal;
    },
    posterType(newVal) {
      this.type = newVal;
    },
    name(newVal) {
      this.posterName = newVal;
    },
  },

  methods: {
    // 班主任下载学习海报
    downloadByBlobClass(url, name) {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob(async (blob) => {
          const data = [
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ];
          await navigator.clipboard.write(data);
          this.$message.success('复制成功');
          this.iconLoading = false;
        });
      };
    },
    onCancelReport(e) {
      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: this.subject,
          poster_id: this.posterId,
          _event: e.target.tagName === 'BUTTON' 
          ? 'CAstudio_CreatePosterPop_CloseClick' 
          : 'CAstudio_CreatePosterPop_ShutClick',
          User_id: getUserUUID(),
          Ocr: this.codeIdent || '',
        },
      });

      this.visibleReport = false;
    },
    queryStudentClassHour() {},
    copyPoster() {
      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: this.subject,
          poster_id: this.posterId,
          User_id: getUserUUID(),
          _event: 'CAstudio_CreatePosterClick',
        },
      });

      if (this.type == 'recommend' && this.subject === 'MATH') {
        const params = {
          studentId: this.studentProfile.uuid,
        };
        queryStudentClassHourNew(params).then((res) => {
          let data = res.data.content;
          console.log(data);
          if (data.mathRemainingCu <= 0) {
            this.$message.warning('数学剩余课时大于0，才可生成此海报参与活动');
          } else {
            this.visibleReport = true;
            this.handlePoster();
          }
        });
      } else {
        this.visibleReport = true;
        this.handlePoster();
      }
    },
    onSelectChange(selectedRowKeys) {
      this.$nextTick(() => {
        this.selectedRowKeys = selectedRowKeys;
      });
    },
    onSelect(record, selected) {
      selected
        ? this.selectedRows.push(record)
        : this.selectedRows.splice(
            this.selectedRows.findIndex((x) => x.uuid === record.uuid),
            1,
          );
    },
    onSelectAll(selected, selectedRows, changeRows) {
      this.selectedRows = selected
        ? this.selectedRows.concat(changeRows)
        : this.selectedRows.filter((x) => !changeRows.find((i) => i.uuid === x.uuid));
    },
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.pageSize = pagination.pageSize;
      this.currentPage = pagination.current;
      this.getStudentInfo();
    },
    increasePercent() {
      let percent = this.percent + 10;
      console.log(percent);
      if (percent > 90) {
        percent = 90;
      }
      this.percent = percent;
    },
    //获取海报水印码

    // 批量下载海报
    batchDownload() {
      if (this.selectedRows.length === 0) {
        this.$message.warning('请选择学生');
        return;
      }
      this.percent = 0;
      // 进度条的比例
      const progressTime = setInterval(() => {
        this.increasePercent();
      }, 500);
      this.visiblePercent = true;
      this.selStudents = this.selectedRows.map((item) => {
        return {
          studentCode: item.code,
          studentName: item.fullName,
        };
      });
      //拓科的批量下载
      if (this.posterType === 'expand') {
        const params = {
          posterUrl: this.posterSrc,
          students: this.selStudents,
          classAdminId: this.userID,
          activityId: this.posterId,
          link: this.link,
          subject: this.subject === 'CHINESE' ? 'Chinese' : 'Math',
        };

        const zipName = `专属海报学生${this.selStudents.length}${this.posterName}-${this.$moment(new Date()).format(
          'YYYYMMDDHHmmss',
        )}`;
        batchDownloadExpand(params, {
          responseType: 'blob',
        }).then((res) => {
          clearInterval(progressTime);
          this.percent = 100;
          this.visiblePercent = false;
          this.visible = false;
          downloadBlob(`${zipName}.zip`, res.data);
        });
      } else {
        const params = {
          subject: this.subject,
          posterUrl: this.posterSrc,
          posterName: this.posterName,
          students: this.selStudents,
          posterId: this.posterId,
        };

        const zipName = `专属海报学生${this.selStudents.length}${this.posterName}-${this.$moment(new Date()).format(
          'YYYYMMDDHHmmss',
        )}`;
        batchDownload(params, {
          responseType: 'blob',
        }).then((res) => {
          clearInterval(progressTime);
          this.percent = 100;
          this.visiblePercent = false;
          this.visible = false;
          downloadBlob(`${zipName}.zip`, res.data);
        });
      }
    },
    // 搜索服务学生列表
    getStudentInfo(act) {
      if (act === 'search') {
        this.currentPage = 1;
      }
      const params = {
        name: this.studentName,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };

      getStudentInfoPage(params).then((res) => {
        const studentList = res.data.content;

        if (studentList.length === 0) {
          this.$store.commit('showMessage', {
            message: '没有找到该学生',
            type: 'error',
          });
          this.studentPosterList = [];
        } else {
          const pagination = { ...this.pagination };
          pagination.total = res.data.totalCount;
          this.pagination = pagination;
          this.studentPosterList = studentList;
        }
      });
    },

    onSearch() {
      this.getStudentInfo('search');
    },
    handlePosterAll() {
      this.percent = 0;
      // 进度条的比例
      const progressTime = setInterval(() => {
        this.increasePercent();
      }, 500);
      this.visiblePercent = true;
      let params = {};
      //拓科的批量下载
      if (this.posterType === 'expand') {
        params = {
          classAdminId: this.userID,
          activityId: this.activityId,
          link: this.link,
          subject: this.subject,
          posterUrl: this.posterSrc,
          posterName: this.posterName,
          posterId: this.posterId,
          ...this.studentParams,
        };
      } else {
        params = {
          subject: this.subject,
          posterUrl: this.posterSrc,
          posterName: this.posterName,
          posterId: this.posterId,
          ...this.studentParams,
        };
      }

      const zipName = `专属学生海报${this.$moment(new Date()).format('YYYYMMDDHHmmss')}`;
      batchDownloadExpandNew(params).then((res) => {
        clearInterval(progressTime);
        this.percent = 100;
        this.visiblePercent = false;
        const link = document.createElement('a'); // 创建a标签
        link.href = res.data.content;
        // link.download ="学生专属海报.zip" // 重命名文件
        link.click();
      });

      // this.visible = true;
      // this.getStudentInfo();
    },
    async handlePoster() {
      console.log(this.posterType);
      //  const loading = this.$loading({
      //     lock: true,
      //     text: '下载中',
      //     spinner: 'el-icon-loading',
      //     background: 'rgba(0, 0, 0, 0.7)',
      //   });
      //拓科的
      this.spinning = true;

      //只有分享海报才有识别码
      if (this.posterType === 'share') {
        const params = {
          studentCode: this.studentProfile.code,
          postUrl: this.src,
          postId: this.posterId,
        };
        await queryWaterMarkCode(params).then((res) => {
          this.codeIdent = res.data.content;
        });
      }

      if (this.posterType === 'expand') {
        const param = {
          studentCode: this.studentProfile.code,
          classAdminId: this.userID,
          activityId: this.posterId,
          link: this.link,
          subject: this.subject.substring(0, 1) + this.subject.substring(1).toLowerCase(),
        };
        getExpandPosterQrcode(param)
          .then((response) => {
            return `data:image/png;base64,${btoa(
              new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            )}`;
          })
          .then((data) => {
            this.spinning = false;
            this.qrcodeImg = data;
            const { fullName, code } = this.studentProfile;
            //   const canvas = document.getElementById('mycanvas');
            // downLoadPosterImg({
            //   bgImg: this.posterSrc,
            //   qrcodeImg: this.qrcodeImg,

            //   fullName,
            //   code,
            //   posterName: this.posterName,
            //   canvas,
            // }).then((url) => {

            //       this.spinning=false
            //         this.posterUrl=url;

            // });
          })
          .catch((err) => {
            this.spinning = false;
            //  loading.close();
          });
      } else {
        //获取识别码，只有分享海报才加
        const param = {
          studentCode: this.studentProfile.code,
          subject: this.subject,
          posterId: this.posterId,
        };
        getPosterQrcode(param)
          .then((response) => {
            console.log(response);
            return `data:image/png;base64,${btoa(
              new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), ''),
            )}`;
          })
          .then((data) => {
            this.qrcodeImg = data;
            const { fullName, code } = this.studentProfile;
            this.spinning = false;
            //  const canvas = document.getElementById('mycanvas');
            // downLoadPosterImg({
            //   bgImg: this.posterSrc,
            //   qrcodeImg: this.qrcodeImg,
            //   fullName,
            //   code,
            //   posterName: this.posterName,
            //   canvas,
            // }).then((url) => {
            //    this.spinning=false
            //   console.log(url)
            //   this.posterUrl=url;

            // });
          })
          .catch((err) => {
            this.spinning = false;
            // loading.close();
          });
      }

      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: this.subject,
          poster_id: this.posterId,
          _event: 'CAstudio_CreatePosterPop',
          User_id: getUserUUID(),
          Ocr: this.codeIdent || '',
        },
      });
    },
    handleSubmit() {
      reportEvent({
        data: {
          Code: this.studentProfile.code,
          subject: this.subject,
          poster_id: this.posterId,
          _event: 'CAstudio_CreatePosterPop_CopyClick',
          User_id: getUserUUID(),
          Ocr: this.codeIdent || '',
        },
      });

      if (this.posterType === 'share') {
        if (!this.codeIdent || !this.qrcodeImg) {
          this.$message.warning('图片生成中，请稍后复制');
          return false;
        }
      } else {
        if (!this.qrcodeImg) {
          this.$message.warning('图片生成中，请稍后复制');
          return false;
        }
      }

      this.iconLoading = true;
      this.$nextTick(() => {
        html2canvas(this.$refs.studyReportTemplateTC, {
          useCORS: true, // 【重要】开启跨域配置
          backgroundColor: 'transparent',
          scale: 2, //按比例增加分辨率 (2=双倍).
          dpi: window.devicePixelRatio * 2, //设备像素比
        }).then((canvas) => {
          const dataURL = canvas.toDataURL('image/png', 1.0);

          //复制成功

          canvas.toBlob(async (blob) => {
            const data = [
              new ClipboardItem({
                [blob.type]: blob,
              }),
            ];
            await navigator.clipboard.write(data);
            this.$message.success('复制成功');
            this.iconLoading = false;
          });
        });
      });
    },
  },
  mounted() {
    this.studentData = this.studentPosterList;
    //从学生列表页面过来
    this.pageType = this.$route.query.pageType;
    console.log(this.pageType);
    if (this.pageType) {
      this.studentParams = JSON.parse(this.$route.query.batchParams);
    }
  },
};
</script>

<style lang="less">
.poster-item {
  width: 248px;
  height: 440px;
  margin: 20px 15px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    background: #d8d8d8;
    border-radius: 13px;
  }
  &:hover {
    height: 455px;
    width: 257px;
    margin: 20px 10px 0px 10px;
  }
  .item-modal {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
    border-radius: 14px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .download-title {
      width: 114px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
      margin-bottom: 20px;
      .student-name {
        color: #43d186;
        margin: 0 5px;
      }
    }
    .download-btn {
      width: 114px;
      height: 32px;
      background: #43d186;
      // box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      span {
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        line-height: 22px;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
      }
    }
    .icon_enter {
      background: #43d186;
      border-radius: 0;
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
}
.canvas {
  display: none;
}
.percent-txt {
  text-align: center;
  margin: 10px 0;
}
.spin-content {
  width: 100%;
  position: absolute;
  left: 0;
  top: 40%;
  text-align: center;
}
.modal-height {
  position: relative;
}
.studyReportTemplate {
  position: relative;
}
.codeIdent {
  position: absolute;
  color: #fff;
  font-weight: bold;
  top: 24px;
  right: 0;
  padding: 5px 12px 5px 15px;
  border-radius: 20px 0 0 20px;
  background: rgba(47, 46, 46, 0.6);
  font-size: 16px;
}
.qrCodeShare {
  width: 85px;
  height: 85px;
  position: absolute;
  right: 17px;
  bottom: 16px;
}
.qrCode {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 21px;
  bottom: 25px;
}
</style>
