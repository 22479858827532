var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"poster-item",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[_c('img',{attrs:{"src":_vm.posterSrc}}),_c('canvas',{staticClass:"canvas",attrs:{"id":"mycanvas","width":"750","height":"1334"}}),(_vm.hover)?_c('div',{staticClass:"item-modal"},[_c('div',[(_vm.pageType !== 'studentList')?_c('div',{staticClass:"download-title"},[_vm._v(" 点击生成"),_c('span',{staticClass:"student-name"},[_vm._v(_vm._s(_vm.studentProfile.fullName)+"("+_vm._s(_vm.studentProfile.code)+")")]),_vm._v("的专属海报 ")]):_vm._e(),(_vm.pageType === 'studentList')?_c('div',{staticClass:"download-btn",on:{"click":_vm.handlePosterAll}},[_c('img',{staticClass:"icon_enter",attrs:{"src":require("@/assets/backlog_icon_download.png")}}),_c('span',{staticClass:"look-btn"},[_vm._v("批量下载")])]):_c('div',{staticClass:"download-btn",on:{"click":_vm.copyPoster}},[_c('span',{staticClass:"look-btn"},[_vm._v("生成专属海报")])])])]):_vm._e(),_c('a-modal',{attrs:{"maskClosable":false,"title":"批量下载","ok-text":"开始下载","cancel-text":"取消"},on:{"ok":_vm.batchDownload},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("请先选择要下载海报的学生")]),_c('a-input-search',{attrs:{"placeholder":"搜索学生姓名或学号","enter-button":"搜索","size":"large"},on:{"search":_vm.onSearch},model:{value:(_vm.studentName),callback:function ($$v) {_vm.studentName=$$v},expression:"studentName"}}),_c('a-table',{attrs:{"rowKey":function (record) { return record.uuid; },"maskClosable":false,"pagination":_vm.pagination,"size":"small","scroll":{ y: 400 },"row-selection":{
        onSelect: _vm.onSelect,
        onSelectAll: _vm.onSelectAll,
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"columns":_vm.columns,"data-source":_vm.studentPosterList},on:{"change":_vm.handleTableChange}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._v(" 已选"),_c('span',{staticStyle:{"color":"#009cff"}},[_vm._v(_vm._s(_vm.selectedRowKeys.length))]),_vm._v("人 ")])],1),_c('a-modal',{attrs:{"maskClosable":false,"closable":false,"footer":null},model:{value:(_vm.visiblePercent),callback:function ($$v) {_vm.visiblePercent=$$v},expression:"visiblePercent"}},[_c('div',{staticClass:"percent-txt"},[_vm._v("批量生成并下载学生专属海报中，请稍后···")]),_c('a-progress',{attrs:{"percent":_vm.percent,"status":"active"}})],1),_c('a-modal',{attrs:{"title":"海报详情","okButtonProps":{
      props: {
        loading: _vm.iconLoading,
      },
    },"visible":_vm.visibleReport,"okText":"复制","cancelText":"关闭","width":"423px","dialogClass":"schedule-related"},on:{"ok":_vm.handleSubmit,"cancel":_vm.onCancelReport}},[_c('div',{staticClass:"modal-height",staticStyle:{"height":"667px"}},[_c('a-spin',{attrs:{"size":"large","spinning":_vm.spinning}},[_c('div',{ref:"studyReportTemplateTC",staticClass:"studyReportTemplate",staticStyle:{"height":"667px"}},[(_vm.posterType === 'share')?_c('span',{staticClass:"codeIdent"},[_vm._v(_vm._s(_vm.codeIdent))]):_vm._e(),_c('img',{class:_vm.type === 'share' ? 'qrCodeShare' : 'qrCode',attrs:{"src":_vm.qrcodeImg}}),_c('img',{ref:"posterImage",staticStyle:{"width":"375px","height":"667px"},attrs:{"src":_vm.src}})])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }